import React from "react";

const Details = () => {

    return(
        <div className='content-top bg-base-100 __variable_36d688'>
            <h1>DETAILS</h1>
            <p> Pixibara: Where Rarity Meets Versatility. Dive into the unique intersection of Pixibara's ERC404 NFTs, blending the best of both fungible flexibility and non-fungible exclusivity.</p>
        </div>
    )
}

export default Details