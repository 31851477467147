import React from "react";

const LeftContainer = () => {
    return(
        <div className='left-container'>
            <div className="left-container-content  bg-accent">
                <h1 className='pixel-text text-white text-center mt-4 lg:text-6xl text-5xl'>PIXIBARA</h1>
            </div>
        </div>
    )
}

export default LeftContainer