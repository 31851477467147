import React, { useState, useEffect } from "react";

const calculateTimeLeft = () => {
    const difference = +new Date('04/03/2024') - +new Date();
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
  
    return timeLeft;
};

const Counter = () => {

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return(
        <div className="grid grid-flow-col gap-5 text-center auto-cols-max">
            <div className="pixel-text flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
              <span className="pixel-text countdown font-mono 2xl:text-9xl lg:text-8xl sm:text-7xl text-4xl ">
                <span  style={{ "--value": timeLeft.days ?? 0 }}></span>
              </span>
              days
            </div>
            <div className="pixel-text flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
              <span className="pixel-text countdown font-mono 2xl:text-9xl lg:text-8xl sm:text-7xl text-4xl">
                <span  style={{ "--value": timeLeft.hours ?? 0 }}></span>
              </span>
              hours
            </div>
            <div className="pixel-text flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
              <span className="pixel-text countdown font-mono 2xl:text-9xl lg:text-8xl sm:text-7xl text-4xl">
                <span  style={{ "--value": timeLeft.minutes ?? 0 }}></span>
              </span>
              min
            </div>
            <div className="pixel-text flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
              <span className="pixel-text countdown font-mono 2xl:text-9xl lg:text-8xl sm:text-7xl text-4xl">
                <span  style={{ "--value": timeLeft.seconds ?? 0 }}></span>
              </span>
              sec
            </div>
          </div>
    )
}

export default Counter