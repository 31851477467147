import React, {useEffect} from 'react';
import './App.css';


import LeftContainer from './components/LeftContainer';
import RightContainer from './components/RightContainer';

function App() {

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', 'retro');
    document.title = 'Pixibara'
  }, []);

  return (
    <div className="custom-container">
      <div className="relative min-h-screen ">       
        <div className="flex flex-col bg-base-200 min-h-screen">
          
          <div className='content-container'>
          
         
          <LeftContainer />
          <RightContainer />
            
          </div>
        
        </div>
      </div>
      
    </div>
  );
}

export default App;
