import React from "react";

import Counter from './Counter';
import LunchingNfts from "./LunchingNfts";
import Details from "./Details";
import Socials from "./Socials";

const RightContainer = () => {

    return(
        <div className='right-container'>
            <div className='right-container-top bg-base-100  sub-container'>
                <LunchingNfts />
                <div className='rct-rs'>
                    <Details />
                    <Socials />
                </div>
            </div>
            <div className='right-container-bottom '>
                <h1 className="xl:text-7xl md:text-6xl sm:text-5xl text-4xl mb-8 pixel-text"><strong>Coming soon ...</strong></h1>
                <Counter />
            </div>  
        </div>
    )
}

export default RightContainer