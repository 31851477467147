import React from "react";
import { FaDiscord, FaXTwitter, FaMedium } from "react-icons/fa6";

const Socials = () => {
    return(
        <div className='content-bottom bg-neutral'>
            <h1 className='glowing-text'>SOCIALS</h1>
            <a href='https://discord.gg/hEwA2BGpJB' target='_blank' className="social-icon mask mask-hexagon-2" ><FaDiscord /></a>
            <a href='https://twitter.com/pixibara' target='_blank' className="social-icon mask mask-hexagon-2" ><FaXTwitter /></a>
            <a href="#" target="_blank" className="social-icon mask mask-hexagon-2"><FaMedium /></a>
        </div>
    )
}

export default Socials