import React from "react";

import PixelRevealImage from './RevealImage';

const LunchingNfts = () => {

    return(
        <div className='rct-ls bg-accent'>
            <div className='canvas-container'>
            <PixelRevealImage src={''} pixelRevealRate={6000} pixelsRevealedAtOnce={10} startDate="2024-03-12T00:00:00Z"/>
            </div>
            <div className='under-canvas-description'>
            <h3>Lunching only </h3>
            <h1 >10000</h1>
            <h2>PixiBara NFTS</h2>
            </div>
        </div>
    )
}

export default LunchingNfts